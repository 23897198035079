import React from "react";
import web3 from "./web3";
import distribute from "./Distribute";
import './App.css';

class App extends React.Component {
  state = {
    balance: "",
    message: "",
  };

  async componentDidMount() {
    const balance = await web3.eth.getBalance(distribute.options.address);

    this.setState({ balance});
  }

  onClick = async () => {
    const accounts = await web3.eth.getAccounts();
    this.setState({ message: "Distributing now..." });

    await distribute.methods.Withdraw().send({from: accounts[0], value: 0});
    
    this.setState({ message: "Royalties Distributed!" });
  };

  render() {
    return (
      <div className= "App">
        <div className = "Distribute-text">This contract holds {web3.utils.fromWei(this.state.balance, 'ether')} MATIC</div>
        <button onClick={this.onClick}>DISTRIBUTE NOW</button>
        <div className = "Process-text">{this.state.message}</div>
      </div>
    );
  }
}
export default App;